export default [
  {
    title: 'Người lao động',
    icon: 'UserIcon',
    children: [
      {
        title: 'Người lao động',
        route: 'worker',
      },
      {
        title: 'Đề nghị cấp giấy phép người lao động',
        route: 'work-permit',
      },
      {
        title: 'Phân loại sức khỏe',
        route: 'health-classification',
      },
    ],
  },
]
