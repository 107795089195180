export default [
  {
    title: 'Hệ thống',
    icon: 'UsersIcon',
    children: [
      // {
      //   title: 'Người dùng',
      //   route: 'list-user',
      // },
      // {
      //   title: 'Kiểu người dùng',
      //   route: 'list-user-type',
      // },
      // {
      //   title: 'Vị trí công việc',
      //   route: 'position-job',
      // },
      // {
      //   title: 'Nhóm người dùng',
      //   route: 'User-Group',
      // },
      {
        title: 'Thùng rác',
        route: 'Trash-Bin',
      },
      // {
      //   title: 'Cơ cấu tổ chức',
      //   route: 'list-struct',
      // },
    ],
  },
]
