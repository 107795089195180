export default [
  {
    title: 'Doanh nghiệp',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Thông tin doanh nghiệp',
        route: 'business-add-orther',
      },
      // {
      //   title: 'Quản lý tài liệu ký số',
      //   route: 'list-businessSector',
      // },
      {
        title: 'Quản lý tài liệu ký số',
        route: 'manage-doc',
      },
      // {
      //   title: 'Khai báo tai nạn lao động',
      //   route: 'declare-accident',
      // },
      // {
      //   title: 'Đề nghị cấp phép kiểm định ATVSLĐ',
      //   route: 'licensing',
      // },
      // {
      //   title: 'Đề nghị huấn luyện kiểm định ATVSLĐ',
      //   route: 'training-atvsld',
      // },
      // {
      //   title: 'Chi phí ATVSLĐ',
      //   route: 'cost-atvsld',
      // },
      // {
      //   title: 'Quản lý bồi dưỡng bằng hiện vật',
      //   route: 'foster',
      // },
      // {
      //   title: 'Quan trắc môi trường lao động',
      //   route: 'environmental-monitoring',
      // },
      // {
      //   title: 'Quản lý thiết bị',
      //   route: 'manage-equip',
      // },
      // {
      //   title: 'Khai báo thiết bị',
      //   route: 'declare-equipment',
      // },
      // {
      //   title: 'Kiểm định thiết bị',
      //   route: 'inspect-equipment',
      // },
      // {
      //   title: 'Phân loại sức khỏe',
      //   route: 'health-classification',
      // },
      // {
      //   title: 'Phân loại báo cáo',
      //   route: 'classify-report',
      // },
      // {
      //   title: 'Tình hình nộp báo cáo',
      //   route: 'status-report',
      // },
      // {
      //   title: 'Tài liệu ký số',
      //   route: 'digitally-signed',
      // },
    ],
  },
]
